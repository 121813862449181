.HomeDiv {  
  position: absolute;  
  right: 0px;
  top: 0px;
  width: calc(100vw - 300px);
  height: calc(100vh - 153px);
  overflow: hidden;
}
.logoDiv {
  display: flex;
  justify-content: center;
}
.logo {
  padding-top: 20px;
  height: auto;
  width: 200px;
  filter: drop-shadow(0px 5px 4px #000);
}
.educationTitle h4, .experienceTitle h4 {
  color: #008fd3;
  -webkit-text-stroke: 1px black;
  font-size: 1.2em;
}
.infoDiv {
  display: flex;
  margin-top: -100px;
}
.educationWrapper, .experienceWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
}
.educationInfo {
  width: 60%;
  text-align: center;
}
.educationSpacing {
  padding-top: 5px;
}
.experienceInfo {
  width: 60%;
  text-align: center;
}
#newEd {
  padding-top: 1em;
}
.backgroundGIF {
  opacity: 0.75;
  position: absolute;
  right: 0;
  top: 0;
  width: calc(100vw - 300px);
  height: calc(100vh - 100px);
  z-index: -1;
}
@media screen and (max-width: 950px){
  .HomeDiv{
    display: none;
  }
}