.carousel-inner {
  height: 325px;
  width: calc(100vw - 600px);
  display: flex;
  justify-content: center;
}
.carousel-item {
  height: 100%;
}
.carousel-inner img {
  height: 100%;
  width: auto!important;
}

@media screen and (max-height: 719px) {
  .carousel{
    display: none;
  }
}
