.footerDiv {
  height: 150px;
}
#footerIcons {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  list-style: none;
  height: 100%;
  background-color: #e22020;
  border-top: 3px solid #f8f004;
  box-shadow: 0px -3px 10px 0px black;
}
.footerIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100px;
  border-radius: 10px;
  background-color: black;
  color: #e22020;
}
.links {
  text-decoration: none;
}
.youtubeArrow {
  height: 0;
  width: 0;
  border-left: 40px solid white;
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent;
  transform: translateX(5px);
}
.imgurIcon {
  width: 100%;
  height: auto;
  padding: 0 10px 0 10px;
}
.resumePic {
  width: 100%;
  height: auto;
  padding: 20%;
}

@media screen and (max-width: 950px){
  .footerDiv {
    width: 100%;
    height: 75px;
    position: sticky;
    bottom: 0;
  }
  .footerIcon {
    height: 50px;
    width: 50px;
  }
  .youtubeArrow {
    border-left: 20px solid white;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    transform: translateX(1px);
  }
}