.LeftBannerHomeDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: calc(100vh - 153px);
  background-color: #e22020c7;
  overflow: hidden;
  border-right: 3px solid #f8f004;
  box-shadow: 3px 0px 10px 0px black;
}
.headshotDiv {
  box-sizing: border-box;
  background-color: white;
  border-radius: 50%;
  height: 275px;
  width: 275px;
  overflow: hidden;
  box-shadow: inset 0px 0px 10px 2px black;
}
.headshotDiv img {
  height: 100%;
  width: auto;
}
.missionTitle .title {
  color: #008fd3;
  -webkit-text-stroke: 1px black;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
}
.missionStatement {
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
}
.hidden {
  display: none;
}

@media screen and (max-width: 950px) {
  .LeftBannerHomeDiv {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    border-right: none;
    box-shadow: none;
  }
  .statement {
    padding: 0 10% 10% 10%;
  }
  .hidden {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 10% 25vh 10%;
  }
  .headshotDiv {
    margin: 10% 0 10% 0;
  }
}